<template>
	<div :class="{'block-inner': true, 'disabled': isDisabled}">
		<header v-html="question"></header>
		<draggable v-bind="dragOptions" :list="block.items" @end="save">
			<li v-for="(item, index) in block.items" :key="index">
				<span>{{ item.label }}</span>
				<IconMoveVertical :width="16" :height="16" class="move-item" />
			</li>
		</draggable>
	</div>
</template>

<script>
	import {jsonToHtml} from '../../inc/text';
	import answerMixin from '../../mixins/answerMixin';
	import IconMoveVertical from '../icons/IconMoveVertical';
	import draggable from 'vuedraggable';
	import Store from '../../inc/store';

	export default {
		components: {
			IconMoveVertical,
			draggable
		},
		mixins: [answerMixin],
		props: {
			block: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				dragOptions: {
					animation: 200,
					disabled: this.isDisabled,
					direction: 'vertical',
					tag: 'ol'
				}
			};
		},
		computed: {
			question() {
				return jsonToHtml(this.block.content);
			},
			answer() {
				const groupAnswers = Store.groupAnswers.filter(answer => answer.blockId === this.block.id);

				if(groupAnswers.length) {
					return groupAnswers[0];
				}

				return null;
			},
			isDisabled() {
				return Store.session.timeEnded || Store.courseGroupProgress.timeGraded;
			}
		},
		mounted() {
			this.$nextTick(() => {
				// Unscrable items if the user has answered the question
				if(this.answer && this.answer.answers && this.answer.answers.length) {
					this.block.items = this.block.items
						.map((item, index) => {
							item.sort = this.answer.answers[0].findIndex(answerId => answerId === item.id);

							if(item.sort === -1) {
								item.sort = index;
							}

							return item;
						})
						.sort((a, b) => a.sort - b.sort)
						.map(item => {
							Reflect.deleteProperty(item, 'sort');

							return item;
						});
				}
			});
		},
		methods: {
			save() {
				const answer = this.block.items.map(item => item.id);

				this.sendAnswer([answer]);
			}
		}
	};
</script>

<style lang="scss" scoped>
	ol {
		list-style-type: none;
		margin: 0;
		padding: 0;
		max-width: 500px;
	}

	li {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		background: $color__white;
		font-size: 1.25rem;
		width: 100%;
		border: none;
		box-shadow: $box_shadow__input;
		border-radius: $border_radius;
		padding: 0.5rem 1rem;
		margin-bottom: $default_padding / 2;
		line-height: 1em;
		outline: none !important;
		transition: box-shadow 0.2s ease-out;
		cursor: grab;

		&:active {
			cursor: grabbing;
		}
	}

	.disabled li {
		cursor: default;
	}
</style>